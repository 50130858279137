export default {
  main: {
    title: 'Title',
    setting: {
      theme_title: 'Theme configuration',
      page_mode: 'Page Mode',
      nav_mode: 'Navigation Mode',
      layout_left: 'Side Layout',
      layout_top: 'Top Layout',
      layout_mix: 'Mix Layout',
      theme_style: 'Theme Style Settings',
      primary_color: 'Primary Color',
      success_color: 'Success Color',
      error_color: 'Error Color',
      warning_color: 'Warning Color',
      font_size: 'Font Size',
      border_radius: 'Border Radius',
      wireframe: 'wireframe',
      page_style: 'Page Style Settings',
      menu_drag: 'Menu Drag And Drop',
      menu_width: 'Menu Width',
      open_keys: 'Expand Menu',
      show_menu: 'Show Menu',
      collapsed: 'Collapse Menu',
      show_collapse: 'Show Collapse Button',
      menu_theme: 'Theme Menu',
      menu_theme_config: {
        light: 'Light',
        dark: 'Dark',
      },
      page_theme: 'Theme Page',
      page_theme_config: {
        normal: 'Normal',
        weakness: 'Weakness',
        grieve: 'Grieve',
      },
      fixed_footer: 'Fixed Footer',
      happy_mode: 'Happy Mode',
      message:
        "After configuration, it only takes effect temporarily. To truly apply to the project, click the 'Copy Configuration' button below and replace the configuration in src/configs/settings.json",
      copy_button: 'Copy Configuration',
      copy_success: 'Copy successful, please paste into the src/configs/settings.json file',
      close_button: 'Close',
      reset_button: 'Reset',
      reset_title: 'Reset Configuration',
      reset_descript: 'Are you sure you want to reset the configuration?',
    },
    header: {
      exit_full: 'Exit full screen',
      entry_full: 'Full screen',
      exit: 'Log out',
      personal: 'Personal Center',
      default_name: 'unknown',
      setting: 'System setting',
      tabs: {
        close_all: 'Close All Tabs',
        close_other: 'Close Other Tabs',
        close_left: 'Close Left Tabs',
        close_right: 'Close Right Tabs',
        close_current: 'Close Current Tabs',
      },
    },
    form: {
      fold: 'Fold',
      unfold: 'UnFold',
      search: 'Search',
      reset: 'Reset',
    },
    table: {
      refresh: 'Refresh',
      size: 'Size',
      size_config: {
        small: 'small',
        middle: 'middle',
        large: 'large',
      },
      sort: {
        asc: 'Ascending',
        desc: 'Descending',
      },
      custom: 'Customize',
      download: 'Download',
    },
  },
  menu: {
    dashboard: 'Dashboard',
    databoard: 'Data Board',
    workplace: 'Work Place',
    system: 'System Manage',
    user: 'User Manage',
    role: 'Role Manage',
    menu: 'Menu Manage',
    dept: 'Dept Manage',
    components: 'FE Components',
    dictionary: 'Dictionary',
    post: 'Post Manage',
    dict: 'Dict Manage',
    log: 'Log Manage',
    logs: {
      operation: 'Operation Log',
      login: 'Login Log',
    },
    files: 'Files Manage',
    organization: 'organizational structure',
    personal: 'Personal Center',
    personal_message: 'Message Center',
    personal_profile: 'Personal Profile',
    exam: 'Exam Manage',
    report: 'Report Manage',
    transport: 'Transport',
  },
  login_page: 'Login Page',
  error_page: 'Error Page',
  error: {
    not_found: 'Not Found',
    server_error: 'Internal Server Error',
    no_permission: 'User currently does not have permission',
    404: 'Sorry, the page you visited does not exist',
    go_home: 'Return to Home',
  },
  request: {
    jsonp_not_url: 'JSONP requires a URL parameter!',
    jsonp_not_callback: 'JSONP requires a Callback parameter!',
    exception: {
      400: 'Bad Request',
      401: 'User does not have permission',
      403: 'User access has been forbidden',
      404: 'The requested resource does not found',
      408: 'The request has timeout',
      410: 'The requested resource does not exist and is permanently lost',
      411: 'Request rejected （missing Content Length）',
      415: 'Unsupported Media Type',
      500: 'Internal Server Error',
      501: 'Internal Server Error （Not Implemented）',
      502: 'Internal Server Error （Bad Gateway）',
      503: 'Service Unavailable',
      504: 'Gateway Timeout',
      505: 'HTTP Version Not Supported',
      error: 'Unknown Error',
    },
    system_exception: {
      40000: 'The request parameters are incorrect',
      40001: 'The current identity has expired',
      40002: 'You do not have this permission',
      40003: 'Data does not exist',
      40004: 'Api does not exist',
      40005: 'There are no unsupported methods in the interface',
      40006: 'Illegal URL',
      40007: 'Data Duplication',
      50000: 'System Error',
      50001: 'System Maintenance',
      50002: 'Unknown Error',
      50003: 'Data Error',
      50004: 'Account acquisition exception',
      50100: 'Server Internal Error',
      50101: 'Network timeout',
      60101: 'Database Error',
      70101: 'Third party HTTP error',
    },
    networkError: 'Network Error',
  },
  login: {
    descript: 'Out of the box mid to back-end management system',
    user_placeholder: 'Please input your username!',
    pass_placeholder: 'Please input your password!',
    phone_placeholder: 'Please input your phone number!',
    email_placeholder: 'Please input your email address!',
    code_placeholder: 'Please input code!',
    remember: 'Remember me',
    forget: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    get_code: 'Get code',
    go_login: 'Return to login',
    go_signup: 'Quick registration',
    no_account: 'No account',
    account_exists: 'Account exists',
    other_methods: 'Other login methods',
    success_title: 'Login successful',
    success_descript: 'Welcome back',
    expire: 'Login has expired, please log in again',
    type: {
      password: 'Password login',
      phone: 'Mobile login',
      email: 'Email login',
    },
  },
  databoard: {
    pv: 'Page View',
    uv: 'Unique Visitor',
    click: 'Content Click Count',
    month: 'Monthly User Count',
    total: 'Data Total',
    today: 'Today',
    distribution: 'Access application distribution',
    today_pv: 'Today Page View',
    month_add_user: 'Month Add User',
    month_add_order: 'Month Add Order',
    retention_trend: 'User Retention Trend',
    retention_count: 'User Retention Count',
  },
  workplace: {
    early: 'Good morning,',
    early_desc: 'Hope you welcome a new day.',
    morning: 'Good morning,',
    morning_desc: 'Today is another beautiful day!',
    noon: 'Good afternoon,',
    noon_desc: "Let's keep working hard!",
    afternoon: 'Afternoon,',
    afternoon_desc: 'Work hard today.',
    evening: 'Good evening,',
    evening_desc: 'May you have a quiet night.',
    night: 'Late at night,',
    night_desc: 'Remember to rest early.',
    progress: 'Project Progress',
    dynamics: 'Project Dynamics',
    source: 'User Source',
    more: 'more',
    help: 'Help Document',
    manage: 'manage',
    quick: 'Quick Entrance',
    schedule: 'Schedule to Do',
    notice: 'Notice',
  },
  exam: {
    question_library: 'Question Library',
    question: 'Question List',
    testpaper: 'TestPaper',
    examination: {
      list: 'Exam List',
      detail: 'Exam Info',
    },
  },
};
