import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useThemeMenu, useMenuSelected } from 'src/hooks/theme';
import { IMenuDataItem, TargetTypeEnum } from 'src/interface';
import { MenuModeEnum } from 'src/interface/theme';
import { permissionStore } from 'src/stores/permission';
import { tabStore } from 'src/stores/tabs';

type MenuItem = Required<MenuProps>['items'][number] & { children?: MenuItem[] };

interface IProps {
  mode?: MenuModeEnum;
}

export default function LayoutMenus(props: IProps) {
  const { mode: propsMode } = props;
  const { selectedKeys, openKeys, changeOpenKeys, changeSelectedKey } = useMenuSelected();
  const { addTabs } = tabStore((state) => ({
    addTabs: state.addTabs,
  }));
  const { t } = useTranslation();
  const menuMap = useRef<Map<string, IMenuDataItem>>(new Map());
  const navigate = useNavigate();
  const { menuInfo, defaultOpenPath } = permissionStore((state) => ({
    menuInfo: state.menuInfo,
    defaultOpenPath: state.defaultOpenPath,
  }));

  const { menuTheme, mode } = useThemeMenu();

  const convertMenuData = (menuData: IMenuDataItem[]): MenuItem[] => {
    return menuData.map((item) => {
      const antdMenuItem: MenuItem = {
        key: item.path || '', // 你可以根据实际情况选择合适的 key
        icon: item.icon,
        label: item.locale ? t(item.locale) : item.menuName,
      };
      menuMap.current.set(item.path, item);
      if (item.children && item.children.length > 0) {
        antdMenuItem.children = convertMenuData(item.children);
      }

      return antdMenuItem;
    });
  };

  const changeMenu = ({ key, keyPath }: { key: string; keyPath: string[] }) => {
    const menuInfo = menuMap.current.get(key);
    if (menuInfo && menuInfo.path !== defaultOpenPath) {
      addTabs(menuInfo);
    }
    changeSelectedKey([key]);
    changeOpenKeys(keyPath);
    if (menuInfo?.target === TargetTypeEnum.BLANK) {
      window.open(key, menuInfo.target);
      return;
    }
    navigate(key);
  };

  return (
    <Menu
      className="mb-[100px]"
      theme={menuTheme}
      mode={propsMode ? propsMode : mode}
      selectedKeys={selectedKeys}
      defaultSelectedKeys={selectedKeys}
      openKeys={openKeys}
      subMenuOpenDelay={0.2}
      items={convertMenuData(menuInfo)}
      onClick={changeMenu}
      onOpenChange={changeOpenKeys}
    />
  );
}
