import { useMemo } from 'react';
import { Layout } from 'antd';
import clsx from 'clsx';
import { prefixCls } from 'src/constants';
import { MenuModeEnum, ScreenTypeEnum } from 'src/interface/theme';
import { useThemeMenu } from 'src/hooks/theme';
import Menus from '../Menus';
import LogoIcon from '../LogoIcon';
import './index.less';

interface IProps {
  isMobile?: boolean;
  isDrawer?: boolean;
}

export default function BasicSider(props: IProps) {
  const { isMobile, isDrawer } = props;
  const {
    showSider,
    collapsed,
    menuWidth,
    menuTheme,
    isLayoutMix,
    currentWidth,
    showLogo,
    isLayoutSider,
  } = useThemeMenu();

  const baseClassName = `${prefixCls}-layout-sider`;
  const siderClassName = clsx(baseClassName, {
    [`${baseClassName}__fixed`]: true,
    [`${baseClassName}__mix`]: !isMobile && isLayoutMix,
  });

  const showLogoFlag = useMemo(() => {
    return showLogo && (isLayoutSider || isMobile);
  }, [showLogo, isLayoutSider, isMobile]);

  const renderLogo = useMemo(() => {
    if (!showLogoFlag) return null;
    const showTitle = isDrawer ? true : !collapsed;
    return (
      <>
        <div className={`${baseClassName}__logo`}>
          <LogoIcon isShowText={showTitle} />
        </div>
      </>
    );
  }, [baseClassName, collapsed, isDrawer, showLogoFlag]);

  const renderMenu = () => {
    return (
      <div className={`${baseClassName}__menu`}>
        <Menus mode={isDrawer ? MenuModeEnum.Inline : undefined} />
      </div>
    );
  };
  return (
    <>
      {!isMobile && (
        <div
          style={{
            width: currentWidth,
            overflow: 'hidden',
            flex: `0 0 ${currentWidth}px`,
            maxWidth: currentWidth,
            minWidth: currentWidth,
            transition: 'all 0.2s ease 0s',
          }}
        />
      )}
      <Layout.Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        breakpoint={ScreenTypeEnum.XL}
        width={menuWidth}
        theme={menuTheme}
        className={clsx(siderClassName, 'shadow-md')}
        style={{ display: showSider || isDrawer ? 'block' : 'none' }}>
        {renderLogo}
        {renderMenu()}
      </Layout.Sider>
    </>
  );
}
