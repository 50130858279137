/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';
import { RouteObject } from 'src/interface/route';
import Layout from 'src/layout';

const ExamQuestionBank = lazy(() => import('src/pages/exam/questionBank'));
const ExamExercise = lazy(() => import('src/pages/exam/exercise'));
const TestpaperList = lazy(() => import('src/pages/exam/testpaperList'));
const TestpaperInfo = lazy(() => import('src/pages/exam/testpaperInfo'));
const ExaminationList = lazy(() => import('src/pages/exam/examination/list'));
const ExaminationEdit = lazy(() => import('src/pages/exam/examination/edit'));

const examRoute: RouteObject[] = [
  {
    path: '/exam',
    element: <Layout />,
    name: '考试管理',
    children: [
      {
        path: 'bank',
        name: '题库管理',
        element: <ExamQuestionBank />,
      },
      {
        path: 'bank/exercise',
        name: '题库配置',
        element: <ExamExercise />,
      },
      {
        path: 'testpaper',
        name: '试卷管理',
        element: <TestpaperList />,
      },
      {
        path: 'testpaper/info',
        name: '试卷配置',
        element: <TestpaperInfo />,
      },
      {
        path: 'examination',
        name: '考试管理',
        element: <ExaminationList />,
      },
      {
        path: 'examination/info',
        name: '考试配置',
        element: <ExaminationEdit />,
      },
    ],
  },
];

export default examRoute;
