import request from 'src/api/http';
import {
  ICreatePaper,
  IExamInfo,
  IGetPaperVersionReq,
  IPaperItem,
  IPaperVersionInfo,
  IPaperVersionItem,
  IQueryExamination,
  IQueryPaper,
  IQueryQuestion,
  IQueryQuestionLibrary,
  IQuestionBase,
  IQuestionItem,
  IQuestionLibraryItem,
  ISubmitPaper,
  IUpdatePaper,
} from '../types/exam';
import { IResultList } from '../types/common';

export const getQuestionLibraryList = (params: IQueryQuestionLibrary) => {
  return request.get<IResultList<IQuestionLibraryItem>>('/exam/question_library/list', { params });
};

export const deleteQuestionLibrary = (ids: string[]) => {
  return request.delete(`/exam/question_library/delete`, { data: { ids } });
};

export const createQuestionLibrary = (data: { questionLibraryName: string }) => {
  return request.post<boolean>('/exam/question_library/create', { data });
};

export const updateQuestionLibrary = (data: {
  questionLibraryName: string;
  questionLibraryId: string;
}) => {
  return request.put<boolean>('/exam/question_library/update', { data });
};

export const shareQuestionLibrary = (data: { questionLibraryId?: string; userId?: number }) => {
  return request.post<boolean>('/exam/question_library/share', { data });
};

export const getQuestionLibraryDetail = (questionLibraryId: string) => {
  return request.get<IQuestionLibraryItem>(`/exam/question_library/detail`, {
    params: { questionLibraryId },
  });
};

export const getQuestionList = (params: IQueryQuestion) => {
  return request.get<IResultList<IQuestionLibraryItem>>('/exam/question/list', { params });
};

export const createQuestion = (data: Partial<IQuestionItem>) => {
  return request.post<boolean>('/exam/question/create', { data });
};

export const deleteQuestion = (ids: string[]) => {
  return request.delete(`/exam/question/delete`, { data: { ids } });
};

export const getTestpaperList = (params: IQueryPaper) => {
  return request.get<IResultList<IPaperItem>>('/exam/paper/list', { params });
};

export const createTestpaper = (data: ICreatePaper) => {
  return request.post<boolean>('/exam/paper/create', { data });
};

export const deleteTestpaper = (ids: string[]) => {
  return request.delete(`/exam/paper/delete`, { data: { ids } });
};

export const updateTestpaper = (data: IUpdatePaper) => {
  return request.post<boolean>('/exam/paper/update', { data });
};
// 获取试卷版本信息
export const getPaperVersionInfo = (paperVersionId: string) => {
  return request.get<IPaperVersionInfo>(`/exam/paper/version/info`, { params: { paperVersionId } });
};
// 创建试卷版本
export const createPaperVersion = (data: { paperId: string }) => {
  return request.post<string>('/exam/paper/create_version', { data });
};
// 试卷版本添加题目
export const addQuestionToPaperVersion = (data: {
  paperVersionId: string;
  question: IQuestionBase;
}) => {
  return request.post<string>('/exam/paper/add_question', { data });
};
// 试卷版本批量添加题目
export const addQuestionsToPaperVersion = (data: {
  paperVersionId: string;
  questions: string[];
}) => {
  return request.post<boolean>('/exam/paper/add_questions', { data });
};
export const deleteQuestionByPaper = (data: { paperVersionId: string; questionId: string }) => {
  return request.post<boolean>(`/exam/paper/remove_question`, { data });
};

// 删除试卷版本
export const deletePaperVersion = (paperVersionId: string) => {
  return request.post(`/exam/paper/remove_version`, { data: { paperVersionId } });
};

// 绑定题目
export const updateVersion = (data: ISubmitPaper) => {
  return request.post<boolean>('/exam/paper/update_version', { data });
};

// 发布版本
export const publishVersion = (paperVersionId: string) => {
  return request.post<boolean>('/exam/paper/publish', { data: { paperVersionId } });
};

// 获取试卷历史版本
export const getPaperVersionHistory = (params: IGetPaperVersionReq) => {
  return request.get<IResultList<IPaperVersionItem>>('/exam/paper/history', { params });
};

// 更新试卷版本中的题目
export const updateQuestionInPaperVersion = (data: {
  paperVersionId: string;
  questionId: string;
  question: IQuestionBase;
}) => {
  return request.post<string>('/exam/paper/update_question', { data });
};

// 获取试卷列表
export const getExamList = (params: IQueryExamination) => {
  return request.get<IResultList<IExamInfo>>('/exam/examination/list', { params });
};
