/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';
import { RouteObject } from 'src/interface/route';
import Layout from 'src/layout';

const Transport = lazy(() => import('src/pages/temporary/transport'));

const systemRoute: RouteObject[] = [
  {
    path: '/temporary',
    element: <Layout />,
    name: '报表管理',
    children: [
      {
        path: 'transport',
        name: '运输单',
        element: <Transport />,
      },
    ],
  },
];

export default systemRoute;
